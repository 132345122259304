
@import '~www/themes/doctors/common/variables';

.the-header-line {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    background-color: $ui-kit-bg-gray-0;
  }

  &__hamburger-btn {
    border: none;
    background-color: transparent;
  }

  &__logo {
    object-fit: contain;
    width: 184px;
    height: 28px;
  }

  &__search-btn {
    display: flex;
    cursor: pointer;
    position: relative;
    font-size: 24px;
    margin: 0;
    border: none;
    background-color: transparent;
  }

  &__search-btn-empty {
    position: relative;
    width: 48px;
  }
}

:deep(.add-point-to-cart) {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 10px;
}

:deep(.cart-icon) {
  height: 24px;
  position: relative;
}
