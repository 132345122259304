
@import '~www/themes/doctors/common/variables';

.the-header {
  position: relative;
  min-height: 64px;
  background-color: $ui-kit-bg-gray-0;

  &__dialog {
    position: fixed;
    inset: 0;
    z-index: 201;
    background-color: $ui-kit-bg-gray-0;
  }

  &__sticky-line {
    transition: opacity .3s ease, transform .3s ease, box-shadow .3s ease;
    transform: translateY(0);
    opacity: 1;

    &.invisible {
      opacity: 0;
      transform: translateY(-100%);
    }

    &.sticky {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 100;

      @include new-elevation-1;
    }
  }
}
